import { Form, Input, Modal } from 'antd';
import React, { PropsWithChildren, useState } from 'react';

import { LexoRank } from 'lexorank';
import { Profile } from '../../db/schema/profiles';
import { useRxCollection } from 'rxdb-hooks';
import { v4 as uuidv4 } from 'uuid';

const PROFILE_NAME_FIELD = 'profileName';

interface ProfileFormProps {
  hide: () => void;
  maxRank: LexoRank;
  show: boolean;
}

export function ProfileForm(props: PropsWithChildren<ProfileFormProps>) {
  const { hide, maxRank, show } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(true);
  const profiles = useRxCollection<Profile>('profiles');

  const onFinish = (formData: Record<string, string>) => {
    // TODO (nirvdrum 17-Jan-22): Don't save the profile if the name is already in use in the local database.
    // form.setFields([{ name: 'profileName', errors: [message] }]);

    setLoading(true);
    profiles!
      .insert({
        id: uuidv4(),
        name: formData[PROFILE_NAME_FIELD],
        rank: maxRank.genNext().toString(),
        user_id: 'DUMMY'
      })
      .then(() => {
        form.resetFields();
        hide();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title='Add New Profile'
      visible={show}
      okText='Add'
      okButtonProps={{ disabled: pending, loading: loading }}
      onCancel={() => {
        form.resetFields();
        hide();
      }}
      onOk={() => form.submit()}
    >
      <Form form={form} layout='inline' onFinish={onFinish}>
        <Form.Item name={PROFILE_NAME_FIELD} label='New Profile Name:' required={true}>
          <Input
            onChange={(event) => {
              setPending(event.target.value.trim().length === 0);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
