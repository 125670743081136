import { Collapse, List } from 'antd';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { QueryConstructor, useRxData } from 'rxdb-hooks';
import React, { MutableRefObject, PropsWithChildren } from 'react';
import { Todo, TodoDoc } from '../../db/schema/todos';

import { LexoRank } from 'lexorank';
import { TodoListItem } from './TodoListItem';
import { TodoStatistics } from './TodoStatistics';
import { maxRank } from '../../lib/lexorankUtils';
import styles from './TodoList.module.scss';

interface TodoListProps {
  filter: (todo: Todo) => boolean;
  profileId: ID;
  maxTodoRank: MutableRefObject<string>;
}

export function TodoList(props: PropsWithChildren<TodoListProps>) {
  const { filter, profileId, maxTodoRank } = props;

  const todoQuery: QueryConstructor<TodoDoc> = (collection) =>
    collection.find().where('profile_id').eq(profileId).sort({ rank: 'desc' });

  const { result, isFetching } = useRxData('todos', todoQuery);
  let todos = result;

  if (todos.length > 0) {
    maxTodoRank.current = todos[0].rank;
  }

  if (isFetching) {
    return <div>Loading...</div>;
  } else {
    console.debug(`Todo Count for ${profileId}: ${todos.length}`);
    todos = todos.filter(filter);
  }

  const onDragEnd = (result: DropResult): void => {
    const destination = result.destination;
    if (!destination) {
      return;
    }

    if (result.source.index !== destination.index) {
      // There are guaranteed to be at least two elements in the todos list if we've made it this far. It's not
      // possible to drag-and-drop an empty list. While you can drag-and-drop a single item list, we only
      // enter this path if an item is not dropped onto itself.

      const lastIndex = todos.length - 1;

      let newRank;
      switch (destination.index) {
        case 0: {
          newRank = maxRank(todos).genNext();
          break;
        }
        case lastIndex: {
          const minRank = LexoRank.parse(todos[lastIndex].rank);
          newRank = minRank.genPrev();
          break;
        }
        default: {
          // If the source index is larger than the destination, we're moving the item up the list.
          // If the source index is smaller than the destination, we're moving the item down the list.
          // It's not possibly for the source and destination to be the same at this point.
          let indexOffset;
          if (result.source.index > destination.index) {
            indexOffset = -1;
          } else {
            indexOffset = 1;
          }
          const a = LexoRank.parse(todos[destination.index].rank);
          const b = LexoRank.parse(todos[destination.index + indexOffset].rank);

          newRank = a.between(b);
        }
      }

      const moving = todos[result.source.index];

      // Re-rank the value in GraphQL.
      moving.update({
        $set: {
          rank: newRank.toString()
        }
      });

      // TODO (nirvdrum 17-Jan-22): Figure out how to avoid the list snapping back before the update persists.
      // todos.splice(result.source.index, 1);
      // todos.splice(destination.index, 0, moving);
    }
  };

  const todoList = todos.filter(filter).map((todo, index) => (
    <Draggable key={todo.id} draggableId={todo.id} index={index}>
      {(provided, _snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles.listItem}
        >
          <List.Item key={todo.id} className={styles.antListItem}>
            <TodoListItem key={todo.id} todo={todo} editable={true} />
          </List.Item>
        </div>
      )}
    </Draggable>
  ));

  return (
    <>
      <Collapse>
        <Collapse.Panel key='0' header='Statistics'>
          <TodoStatistics todos={todos} />
        </Collapse.Panel>
      </Collapse>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={profileId}>
          {(provided, _snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <List>{todoList}</List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
