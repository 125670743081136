import { Checkbox, Tooltip } from 'antd';
import React, { PropsWithChildren, useState } from 'react';
import { Todo, TodoDoc } from '../../db/schema/todos';

import { RxDocument } from 'rxdb';
import { SnippetsOutlined } from '@ant-design/icons';
import { TodoEditForm } from './TodoEditForm';
import styles from './TodoListItem.module.scss';

interface TodoListItemProps {
  editable?: boolean;
  todo: Todo;
}

export function TodoListItem(props: PropsWithChildren<TodoListItemProps>) {
  const { editable, todo } = props;
  const [isEditing, setIsEditing] = useState(false);

  const updateIsCompleted = (isCompleted: boolean): void => {
    (todo as RxDocument<TodoDoc>).updateCompletion(isCompleted);
  };

  const noteDisplay = todo.note ? (
    <Tooltip title={todo.note}>
      <SnippetsOutlined className={styles.todoNoteIcon} data-testid='notes-icon' />
    </Tooltip>
  ) : null;

  if (isEditing) {
    return <TodoEditForm todo={todo} onComplete={() => setIsEditing(false)} />;
  }

  //console.info((todo as TodoDoc).toJSON());

  return (
    <div className={styles.todoListItem}>
      {editable && (
        <Checkbox
          checked={todo.is_completed}
          onChange={() => updateIsCompleted(!todo.is_completed)}
          data-testid='toggle-completion'
        />
      )}
      <div className={styles.todoTitle}>
        <span
          className={todo.is_completed ? styles.completedTodo : ''}
          onClick={() => (editable ? setIsEditing(true) : null)}
        >
          {todo.title}
        </span>
      </div>
      <div>{noteDisplay}</div>
    </div>
  );
}
