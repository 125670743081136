import { Button, Transfer } from 'antd';
import React, { PropsWithChildren } from 'react';
import { Todo, TodoDoc } from '../../db/schema/todos';

import { TodoListItem } from '../Todos/TodoListItem';
import { TransferDirection } from 'antd/lib/transfer';
import { findLast } from '../../lib/arrayHelpers';
import styles from './TodoChainEditor.module.scss';

interface TodoChainEditorProps {
  availableTodos: Todo[];
  chainTodoIds: ID[];
  onCancel: () => void;
  onSubmit: (todoIDs: ID[]) => void;
}

const buildTodoMap = (todos: Todo[]): Map<string, Todo> => {
  const todoMap = new Map<string, Todo>();

  todos.forEach((todo) => {
    todoMap.set(todo.id, todo);
  });

  return todoMap;
};

const isActionable = (todo: Optional<Todo>): boolean => {
  return todo && !todo.is_completed ? true : false;
};

export function TodoChainEditor(props: PropsWithChildren<TodoChainEditorProps>) {
  const { availableTodos, chainTodoIds, onCancel, onSubmit } = props;
  const originalTodoIDs = chainTodoIds;
  const [updatedChainTodoIds, setUpdatedChainTodoIds] = React.useState(originalTodoIDs);

  const todoMap = buildTodoMap(availableTodos);

  const chainTodoList = updatedChainTodoIds.map((id) => todoMap.get(id)!);
  const actionableTodo = findLast(chainTodoList, (todo) => isActionable(todo));
  const remainingTodos = chainTodoList.concat(
    availableTodos.filter((t) => t.rank > actionableTodo!.rank)
  );

  const dataSource: DataSource<Todo>[] = remainingTodos
    .filter((todo) => todo && !todo.is_completed)
    .map((todoDoc) => {
      /*if (todoDoc) {
        console.error("bad todo", todoDoc);
      }*/
      const todo = (todoDoc as TodoDoc).toJSON();
      return { ...todo, key: todo.id };
    });

  const onChange = (targetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    if (direction === 'right') {
      setUpdatedChainTodoIds(updatedChainTodoIds.concat(moveKeys));
    } else {
      // We can only remove newly added todos at this point. We can't remove existing entries from the chain.
      const safeMoveKeys = moveKeys.filter((id) => !originalTodoIDs.includes(id));

      // The first entry is never removable. FVP requires the oldest todo to be the first entry.
      if (updatedChainTodoIds.length > originalTodoIDs.length) {
        // We can't allow todos in the middle of the chain to be deleted because the end of the chain
        // determines the members of the list. So, we either must only allow the last item in the chain
        // to be moved or we must remove the todo and all todos that follow it if the todo is in the middle
        // of the chain. We choose the latter.
        const index = updatedChainTodoIds.findIndex((id) => id === safeMoveKeys[0]);
        setUpdatedChainTodoIds(updatedChainTodoIds.slice(0, index));
      }
    }
  };

  const nextActionQuestion =
    remainingTodos.length > chainTodoIds.length
      ? `What would you like to do more than "${actionableTodo?.title}"?`
      : `Congrats! Your todo chain is complete.`;

  return (
    <div>
      <div className={styles.prompt}>{nextActionQuestion}</div>
      <Transfer
        dataSource={dataSource}
        targetKeys={updatedChainTodoIds}
        render={(item) => <TodoListItem todo={item} editable={false} />}
        showSearch
        showSelectAll={false}
        listStyle={{
          width: '45%',
          height: '60vh',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        titles={['Remaining Options', 'Updated Todo Chain']}
        onChange={onChange}
      />
      <div className={styles.buttonGroup}>
        <Button type='primary' onClick={() => onSubmit(updatedChainTodoIds)}>
          Save Todo Chain
        </Button>

        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
}
