import { Button, Form, Input, Popconfirm, notification } from 'antd';
import React, { PropsWithChildren } from 'react';
import { Todo, TodoDoc } from '../../db/schema/todos';

import { DeleteOutlined } from '@ant-design/icons';
import { RxDocument } from 'rxdb';
import styles from './TodoEditForm.module.scss';

const TODO_TITLE_FIELD = 'todoTitle';
const TODO_NOTES_FIELD = 'todoNotes';

const FORM_LAYOUT = {
  labelCol: { span: 2 },
  wrapperCol: { span: 10 }
};

const FORM_TAIL_LAYOUT = {
  wrapperCol: { offset: 5, span: 10 }
};

interface TodoDetailsProps {
  todo: Todo;
  onComplete: () => void;
}

export function TodoEditForm(props: PropsWithChildren<TodoDetailsProps>) {
  const { onComplete, todo } = props;
  const [form] = Form.useForm();

  const onFinish = (formData: Record<string, string>) => {
    const normalizedNotes = (formData[TODO_NOTES_FIELD] ?? '').trim();

    (todo as RxDocument<TodoDoc>)
      .update({
        $set: {
          title: formData[TODO_TITLE_FIELD].trim(),
          note: normalizedNotes.length === 0 ? undefined : normalizedNotes
        }
      })
      .catch((error) => notification.error({ message: error.message, description: error.stack }));

    onComplete();
  };

  return (
    <div className={styles.container}>
      <Form
        {...FORM_LAYOUT}
        form={form}
        initialValues={{ [TODO_TITLE_FIELD]: todo.title, [TODO_NOTES_FIELD]: todo.note }}
        onFinish={onFinish}
      >
        <Form.Item
          name={TODO_TITLE_FIELD}
          label='Title:'
          rules={[{ required: true, message: 'Please add something to your todo' }]}
        >
          <Input autoFocus={true} onPressEnter={form.submit} />
        </Form.Item>

        <Form.Item name={TODO_NOTES_FIELD} label='Notes:'>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item {...FORM_TAIL_LAYOUT}>
          <Button htmlType='submit' type='primary' disabled={false}>
            Save
          </Button>

          <Button htmlType='submit' disabled={false} onClick={onComplete}>
            Cancel
          </Button>
        </Form.Item>

        <div className={styles.footer}>
          <span>Created on {new Date(todo.created_at).toLocaleDateString()}</span>
          <Popconfirm
            title='Are you sure you want to delete this todo?'
            cancelText='No'
            okText='Yes'
            onConfirm={() => (todo as TodoDoc).remove()}
          >
            <DeleteOutlined data-testid='delete-button' className={styles.deleteIcon} />
          </Popconfirm>
        </div>
      </Form>
    </div>
  );
}
