const HOUR = 1000 * 60 * 60;

export function withinLastNHours(timestamp: Optional<string>, n: number): boolean {
  if (!timestamp) {
    return false;
  }

  const now = new Date();
  const diff = now.getTime() - Date.parse(timestamp);

  return diff < n * HOUR;
}
