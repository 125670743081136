import { Card, Statistic } from 'antd';
import React, { PropsWithChildren } from 'react';

import { Todo } from '../../db/schema/todos';
import styles from './TodoStatistics.module.scss';
import { withinLastNHours } from '../../lib/timeUtils';

interface TodoStatisticsProps {
  todos: Todo[];
}

export function TodoStatistics(props: PropsWithChildren<TodoStatisticsProps>) {
  const { todos } = props;

  const completed = todos.filter((todo) => todo.is_completed);
  const completedPastDay = completed.filter((todo) => withinLastNHours(todo.completed_at, 24));
  const completedPastWeek = completed.filter((todo) => withinLastNHours(todo.completed_at, 24 * 7));

  const createdPastDay = todos.filter((todo) => withinLastNHours(todo.created_at, 24));
  const createdPastWeek = todos.filter((todo) => withinLastNHours(todo.created_at, 24 * 7));

  return (
    <Card title='Progress'>
      <section className={styles.container}>
        <Statistic title='Completed' value={completed.length} suffix={`/ ${todos.length}`} />
        <Statistic title='Completed Past 24 Hours' value={completedPastDay.length} />
        <Statistic title='Completed Past Week' value={completedPastWeek.length} />
        <Statistic title='Created Past 24 Hours' value={createdPastDay.length} />
        <Statistic title='Created Past Week' value={createdPastWeek.length} />
      </section>
    </Card>
  );
}
