import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxCollection,
  RxDocument,
  RxJsonSchema,
  toTypedRxJsonSchema
} from 'rxdb';
import { MAX_LEXORANK_LENGTH, MAX_TIMESTAMP_LENGTH, MAX_UUID_LENGTH } from './constraints';
import { Profile, ProfileDoc } from './profiles';

import { LexoRank } from 'lexorank';
import { v4 as uuidv4 } from 'uuid';

const todoSchemaLiteral = {
  title: 'Todo schema',
  description: 'Todo schema',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: MAX_UUID_LENGTH
    },
    profile_id: {
      type: 'string',
      maxLength: MAX_UUID_LENGTH
    },
    user_id: {
      type: 'string',
      maxLength: MAX_UUID_LENGTH,
      final: true
    },
    title: {
      type: 'string'
    },
    is_completed: {
      type: 'boolean'
    },
    note: {
      type: 'string'
    },
    rank: {
      type: 'string',
      maxLength: MAX_LEXORANK_LENGTH
    },
    completed_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    },
    created_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    },
    updated_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    },
    deleted_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    }
  },
  required: ['title', 'profile_id', 'rank', 'id', 'is_completed', 'created_at'],
  indexes: ['profile_id', 'rank', ['profile_id', 'rank']]
} as const;

const todoSchemaTyped = toTypedRxJsonSchema(todoSchemaLiteral);
export type Todo = ExtractDocumentTypeFromTypedRxJsonSchema<typeof todoSchemaTyped>;
export const todoSchema: RxJsonSchema<Todo> = todoSchemaLiteral;

type TodoMethods = {
  markPartiallyComplete: () => Promise<Todo>;
  profile: () => Promise<Profile>;
  reenter: () => Promise<Todo>;
  updateCompletion: (isCompleted: boolean) => Promise<Todo>;
};

export const todoMethods: TodoMethods = {
  async markPartiallyComplete(this: TodoDoc): Promise<Todo> {
    await this.updateCompletion(true);

    return this.reenter();
  },
  async profile(this: TodoDoc): Promise<Profile> {
    return this.collection.database.profiles.findOne(this.profile_id).exec();
  },
  async reenter(this: TodoDoc): Promise<Todo> {
    const profile = await this.profile();
    const maxRank = await (profile as ProfileDoc).maxTodoRank();

    return this.collection.insert({
      id: uuidv4(),
      profile_id: profile.id,
      title: this.title,
      rank: LexoRank.parse(maxRank).genNext().toString(),
      note: this.note === '' ? undefined : this.note,
      is_completed: false,
      created_at: new Date().toISOString()
    });
  },
  updateCompletion(this: TodoDoc, isCompleted: boolean): Promise<Todo> {
    return this.update({
      $set: {
        completed_at: isCompleted ? new Date().toISOString() : undefined,
        is_completed: isCompleted
      }
    });
  }
};

export type TodoCollectionMethods = unknown;

export type TodoDoc = RxDocument<Todo, TodoMethods>;
export type TodoCollection = RxCollection<Todo, TodoMethods, TodoCollectionMethods>;
