import { Card, Col, Collapse, Radio, Row } from 'antd';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { LexoRank } from 'lexorank';
import { Profile } from '../../db/schema/profiles';
import { Todo } from '../../db/schema/todos';
import { TodoChain } from '../Todos/TodoChain';
import { TodoForm } from '../Todos/TodoForm';
import { TodoList } from '../Todos/TodoList';
import { useRxCollection } from 'rxdb-hooks';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { withinLastNHours } from '../../lib/timeUtils';

interface ProfileTabPaneProps {
  profile: Profile;
}

type TodoFilter = (todo: Todo) => boolean;

const showAllFilter: TodoFilter = (_) => true;
const showOnlyCompletedFilter: TodoFilter = (todo: Todo) => todo.is_completed == true;
const showOnlyUncompletedFilter: TodoFilter = (todo: Todo) =>
  todo.is_completed == false || withinLastNHours(todo.completed_at, 24);

const TODO_FILTER_MAP = {
  all: showAllFilter,
  completed: showOnlyCompletedFilter,
  uncompleted: showOnlyUncompletedFilter
};
type TodoFilterMapKey = keyof typeof TODO_FILTER_MAP;

export function ProfileTabPane(props: PropsWithChildren<ProfileTabPaneProps>) {
  const { profile } = props;
  const maxTodoRank = useRef(LexoRank.min().toString());
  const [searchParams, setSearchParams] = useSearchParams({});
  const [loading, setLoading] = useState(false);
  const todos = useRxCollection<Todo>('todos');

  let activeFilter: TodoFilterMapKey;
  const filterParam = searchParams.get('filter');
  if (filterParam) {
    switch (filterParam) {
      case 'all': {
        activeFilter = 'all';
        break;
      }
      case 'completed': {
        activeFilter = 'completed';
        break;
      }
      default: {
        activeFilter = 'uncompleted';
        break;
      }
    }
  } else {
    activeFilter = 'uncompleted';
  }

  useEffect(() => {
    if (filterParam && filterParam != activeFilter) {
      setSearchParams({ filter: activeFilter });
    }
  }, [activeFilter, filterParam, setSearchParams]);

  const [todoListFilter, setTodoListFilter] = useState<TodoFilter>(
    () => TODO_FILTER_MAP[activeFilter]
  );

  const onSubmit = (title: string, notes: string): void => {
    const trimmedNotes = notes ? notes.trim() : '';

    setLoading(true);
    todos
      ?.insert({
        id: uuidv4(),
        profile_id: profile.id,
        title: title.trim(),
        rank: LexoRank.parse(maxTodoRank.current).genNext().toString(),
        note: trimmedNotes.length === 0 ? undefined : trimmedNotes,
        is_completed: false,
        created_at: new Date().toISOString()
      })
      .finally(() => setLoading(false));
  };

  const onFilterChange = (value: string) => {
    switch (value) {
      case 'completed': {
        setSearchParams({ filter: 'completed' });
        setTodoListFilter(() => TODO_FILTER_MAP[value]);
        break;
      }
      case 'uncompleted': {
        setSearchParams({ filter: 'uncompleted' });
        setTodoListFilter(() => TODO_FILTER_MAP[value]);
        break;
      }
      default: {
        setSearchParams({ filter: 'all' });
        setTodoListFilter(() => TODO_FILTER_MAP['all']);
      }
    }
  };

  const todoStateSwitcher = (
    <Radio.Group
      defaultValue={activeFilter}
      onChange={(event) => onFilterChange(event.target.value)}
      data-testid={`todo-filter-${activeFilter}`}
    >
      <Radio.Button value='all'>All</Radio.Button>
      <Radio.Button value='uncompleted'>Uncompleted</Radio.Button>
      <Radio.Button value='completed'>Completed</Radio.Button>
    </Radio.Group>
  );

  return (
    <div data-testid={`profile-tab-${profile.id}`}>
      <Row>
        <Col span={24}>
          <Collapse>
            <Collapse.Panel key='0' header='Create a Todo'>
              <Card>
                <TodoForm disabled={loading} onSubmit={onSubmit} />
              </Card>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TodoChain profileId={profile.id} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card title='Todos' extra={todoStateSwitcher}>
            <TodoList profileId={profile.id} maxTodoRank={maxTodoRank} filter={todoListFilter} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
