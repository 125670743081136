import React, { useState } from 'react';

import { Card } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

export function UserInfo() {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const [jwt, setJwt] = useState('');

  if (!isLoading && isAuthenticated) {
    const auth0UserId = user?.sub;
    const userName = user?.name;

    getAccessTokenSilently().then((token) => setJwt(token));

    return (
      <Card title='User Info'>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{userName}</td>
            </tr>
            <tr>
              <td>Auth0 ID</td>
              <td>{auth0UserId}</td>
            </tr>
            <tr>
              <td>JWT</td>
              <td>{jwt}</td>
            </tr>
          </tbody>
        </table>
      </Card>
    );
  } else {
    return <div>No user logged in</div>;
  }
}
