import { Layout, Tabs } from 'antd';
import { QueryConstructor, useRxData } from 'rxdb-hooks';
import { useNavigate, useParams } from 'react-router-dom';

import { AccountTabPane } from './AccountTabPane';
import { Profile } from '../db/schema/profiles';
import { ProfileTabPane } from './Profiles/ProfileTabPane';
import React from 'react';
import styles from './App.module.scss';
import { useAuth0 } from '@auth0/auth0-react';

const { TabPane } = Tabs;

const ACCOUNT_TAB_KEY = 'account';

function App() {
  const { profileId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const queryConstructor: QueryConstructor<Profile> = (collection) =>
    collection.find().sort({ rank: 'asc' });
  const { result: profiles, isFetching } = useRxData('profiles', queryConstructor);

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
    return <div>Redirecting to login page...</div>;
  }

  if (isFetching) {
    return <div data-testid='loading-profiles-message'>Loading...</div>;
  }

  const updateUrl = (tabKey: string) => {
    if (tabKey == ACCOUNT_TAB_KEY) {
      navigate(`/${ACCOUNT_TAB_KEY}`);
    } else {
      navigate(`/profiles/${tabKey}`);
    }
  };

  const onTabChange = (tabKey: string) => {
    localStorage.setItem('active-profile', tabKey);
    updateUrl(tabKey);
  };

  const activeTab = profileId
    ? profileId
    : localStorage.getItem('active-profile') || ACCOUNT_TAB_KEY;

  const todoTabs = profiles.map((profile, index) => (
    <TabPane key={index} tab={profile.name}>
      <ProfileTabPane profile={profile} />
    </TabPane>
  ));

  return (
    <div className={styles.layout}>
      <Layout>
        <Layout.Content className={styles.content}>
          <Tabs defaultActiveKey={activeTab} onChange={onTabChange}>
            {todoTabs}
            <TabPane tab='Account' key={ACCOUNT_TAB_KEY}>
              <AccountTabPane profiles={profiles} />
            </TabPane>
          </Tabs>
        </Layout.Content>
      </Layout>
    </div>
  );
}

export default App;
