import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxCollection,
  RxDocument,
  RxJsonSchema,
  toTypedRxJsonSchema
} from 'rxdb';
import { MAX_LEXORANK_LENGTH, MAX_TIMESTAMP_LENGTH, MAX_UUID_LENGTH } from './constraints';

import { Todo } from './todos';

const profileSchemaLiteral = {
  title: 'Profile schema',
  description: 'Profile schema',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: MAX_UUID_LENGTH
    },
    user_id: {
      type: 'string',
      maxLength: MAX_UUID_LENGTH,
      final: true
    },
    name: {
      type: 'string'
    },
    rank: {
      type: 'string',
      maxLength: MAX_LEXORANK_LENGTH
    },
    updated_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    },
    deleted_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    }
  },
  required: ['id', 'user_id', 'name', 'rank'],
  indexes: ['rank']
} as const;

const profileSchemaTyped = toTypedRxJsonSchema(profileSchemaLiteral);
export type Profile = ExtractDocumentTypeFromTypedRxJsonSchema<typeof profileSchemaTyped>;
export const profileSchema: RxJsonSchema<Profile> = profileSchemaLiteral;

type ProfileMethods = {
  maxTodoRank: () => Promise<string>;
  todos: () => Promise<Todo[]>;
};

export const profileMethods: ProfileMethods = {
  async maxTodoRank(this: ProfileDoc): Promise<string> {
    const todo = await this.collection.database.collections.todos
      .findOne({
        selector: {
          profile_id: this.id
        },
        sort: [
          {
            rank: 'desc'
          }
        ]
      })
      .exec();

    return todo.rank;
  },
  todos(this: ProfileDoc): Promise<Todo[]> {
    return this.collection.database.todos.find().where('profile_id').eq(this.id).exec();
  }
};

export type ProfileCollectionMethods = unknown;

export type ProfileDoc = RxDocument<Profile, ProfileMethods>;
export type ProfileCollection = RxCollection<Profile, ProfileMethods, ProfileCollectionMethods>;
