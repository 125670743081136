import React, { PropsWithChildren, useState } from 'react';

import { Button } from 'antd';
import { LexoRank } from 'lexorank';
import { ProfileForm } from './ProfileForm';
import styles from './ProfileListHeader.module.scss';

interface ProfileListHeaderProps {
  maxProfileRank: LexoRank;
}

export function ProfileListHeader(props: PropsWithChildren<ProfileListHeaderProps>) {
  const { maxProfileRank } = props;
  const [showAddProfileForm, setShowProfileForm] = useState(false);

  return (
    <div className={styles.profileListHeader}>
      <ProfileForm
        hide={() => setShowProfileForm(false)}
        maxRank={maxProfileRank}
        show={showAddProfileForm}
      />
      <span>Profiles</span>
      <Button type='primary' onClick={() => setShowProfileForm(true)}>
        Add Profile
      </Button>
    </div>
  );
}
