import {
  GRAPHQL_URL,
  LOWEST_OBJECT_ID,
  REPLICATION_BATCH_SIZE,
  TaskeratorDatabase
} from '../database';
import {
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted
} from 'rxdb/dist/types/types';

import { Auth0ContextInterface } from '@auth0/auth0-react';
import { LexoRank } from 'lexorank';
import { MaybePromise } from 'iter-tools/types/async-iterable';
import { PROFILE_FRAGMENT } from '../../graphql/fragments';
import { Profile } from '../schema/profiles';
import { ProfileGraphQL } from '../../graphql_artifacts/ProfileGraphQL';
import { Replicator } from './Replicator';
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';

interface CheckpointType {
  id: string;
  updatedAt: string;
}

const DEFAULT_CHECKPOINT = {
  id: LOWEST_OBJECT_ID,
  updatedAt: new Date(0).toDateString()
};

export class ProfileReplicator implements Replicator<CheckpointType> {
  private db: TaskeratorDatabase;

  constructor(db: TaskeratorDatabase) {
    this.db = db;
  }

  async setupGraphQLReplication(
    tokenFetcher: Auth0ContextInterface['getAccessTokenSilently']
  ): Promise<RxGraphQLReplicationState<Profile, CheckpointType>> {
    const token = await tokenFetcher();
    const replicationState = this.db.profiles.syncGraphQL({
      url: { http: GRAPHQL_URL },
      headers: { authorization: `Bearer ${token}` },
      deletedField: 'deleted_at',
      pull: {
        batchSize: REPLICATION_BATCH_SIZE,
        queryBuilder: this.pullQueryBuilder,
        modifier: (doc: ProfileGraphQL): MaybePromise<WithDeleted<Profile>> => {
          return {
            ...doc,
            deleted_at: doc.deleted_at || undefined,
            _deleted: !!doc.deleted_at
          };
        },
        responseModifier: (
          plainResponse: ReplicationPullHandlerResult<Profile, CheckpointType>,
          origin,
          requestCheckpoint
        ): MaybePromise<ReplicationPullHandlerResult<Profile, CheckpointType>> => {
          const docs = (plainResponse as unknown as Profile[]).map((doc) => {
            return { ...doc, _deleted: !!doc.deleted_at };
          });
          return {
            documents: docs,
            checkpoint:
              docs.length === 0 || !requestCheckpoint
                ? DEFAULT_CHECKPOINT
                : {
                    id: docs[docs.length - 1].id,
                    updatedAt: docs[docs.length - 1].updated_at!
                  }
          };
        }
      },
      push: {
        batchSize: 1,
        queryBuilder: this.pushQueryBuilder
      },
      live: true
    });

    replicationState.error$.subscribe((error) => {
      if (!error.parameters) {
        console.error('Error that breaks type definitions (ProfileReplicator)!');
        console.debug(error);

        return;
      }

      const firstError = error.parameters.errors?.[0] as unknown as Optional<HasuraError>;

      if (firstError?.extensions.code === 'invalid-jwt') {
        console.info('Refetching JWT due to expiration');

        tokenFetcher().then((token) =>
          replicationState.setHeaders({ authorization: `Bearer ${token}` })
        );

        return;
      }

      if (error.parameters.direction === 'push') {
        const documentData = error.parameters.pushRows![0].newDocumentState;

        if (
          firstError?.message ===
          'Uniqueness violation. duplicate key value violates unique constraint "profiles_user_id_name_key"'
        ) {
          this.db.profiles
            .findOne(documentData.id)
            .exec()
            .then((doc) => {
              doc?.remove();
            });

          documentData._deleted = true;
        } else if (firstError?.extensions.code === 'permission-error') {
          console.error('Profile push replication failed due to a permissions issue', documentData);

          this.db.profiles
            .findOne(documentData.id)
            .exec()
            .then((doc) => doc?.remove());

          documentData._deleted = true;
        } else if (
          firstError?.message ===
          'Uniqueness violation. duplicate key value violates unique constraint "profiles_user_id_rank_key"'
        ) {
          const newRank = LexoRank.parse(documentData.rank).genNext().toString();

          this.db.profiles
            .findOne(documentData.id)
            .exec()
            .then((doc) => {
              doc?.update({
                $set: {
                  rank: newRank
                }
              });
            });

          documentData.rank = newRank;
        } else {
          console.error('Profile push replication failed.', error);
        }
      } else if (error.parameters?.direction === 'pull') {
        console.error('Profile pull replication failed.', error);
      } else {
        console.error('Unknown Profile error direction!', error);
      }

      console.error(error);
    });

    return replicationState;
  }

  pullQueryBuilder(latestPulledCheckpoint: Nullable<CheckpointType>, limit: number) {
    if (!latestPulledCheckpoint) {
      latestPulledCheckpoint = DEFAULT_CHECKPOINT;
    }

    const query = `
      ${PROFILE_FRAGMENT.loc?.source.body}
      query FetchProfiles($updated_at: timestamptz!, $last_id: uuid, $limit: Int) {
        profiles(limit: $limit, where: {
          _or: [
            { updated_at: { _gt: $updated_at } },
            { updated_at: { _eq: $updated_at }, id: { _gt: $last_id } }
          ]},
          order_by: [{ updated_at: asc }, { id: asc }]) {
            ...ProfileGraphQL
        }
      }
    `;

    return {
      query: query,
      variables: {
        last_id: latestPulledCheckpoint.id,
        updated_at: latestPulledCheckpoint.updatedAt,
        limit: limit
      }
    };
  }

  pushQueryBuilder(docs: RxReplicationWriteToMasterRow<Profile>[]) {
    if (docs.length === 1) {
      const doc = docs[0].newDocumentState;

      if (doc._deleted || doc.deleted_at) {
        const query = `
      mutation DeleteProfile($id: uuid!) {
        delete_profiles_by_pk(id: $id) {
          id
        }
      }
      `;

        return {
          query: query,
          variables: {
            id: doc.id
          }
        };
      } else {
        const query = `
      ${PROFILE_FRAGMENT.loc?.source.body}
      mutation UpsertProfile($id: uuid!, $profileName: String!, $rank: String!, $updated_at: timestamptz!) {
        insert_my_users_one(
          object: {
            name: "DUMMY",
            profiles: {
              data: { id: $id, name: $profileName, rank: $rank }
              on_conflict: { constraint: profiles_pkey, update_columns: [name, rank], where: { updated_at: { _lte: $updated_at } } }
            }
          }
          on_conflict: { constraint: users_auth0_id_key, update_columns: last_seen }
        ) {
            profiles {
              ...ProfileGraphQL
          }
        }
      }
    `;

        return {
          query: query,
          variables: {
            id: doc.id,
            profileName: doc.name,
            rank: doc.rank,
            updated_at: doc.updated_at || new Date().toISOString()
          }
        };
      }
    } else {
      throw new Error('ProfileReplicator pushes in batches > 1 are unsupported');
    }
  }

  /*
  subscription(client: Client, replicationState: RxGraphQLReplicationState<Profile, CheckpointType>): () => void {
    const query = `
    ${PROFILE_FRAGMENT.loc?.source.body}
    subscription onProfileChange {
      profiles(limit: 1, order_by: {updated_at: desc}) {
        ...ProfileGraphQL
      }
    }`;

    return client.subscribe(
      { query },
      {
        next: (data) => {
          console.log('WS profile data', data);
          replicationState.run();
        },
        error: (error) => console.error('WS profile error', error),
        complete: () => console.log('WS profile complete')
      }
    );
  }*/
}
