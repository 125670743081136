import { gql } from '@apollo/client';

export const PROFILE_FRAGMENT = gql`
  fragment ProfileGraphQL on profiles {
    id
    user_id
    name
    rank
    deleted_at
    updated_at
  }
`;

export const TODO_FRAGMENT = gql`
  fragment TodoGraphQL on todos {
    id
    profile_id
    title
    note
    rank
    is_completed
    completed_at
    created_at
    deleted_at
    updated_at
  }
`;

export const TODO_CHAIN_FRAGMENT = gql`
  fragment TodoChainGraphQL on todo_chains {
    id
    profile_id
    todo_ids
    is_completed
    completed_at
    created_at
    deleted_at
    updated_at
  }
`;
