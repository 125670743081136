import {
  GRAPHQL_URL,
  LOWEST_OBJECT_ID,
  REPLICATION_BATCH_SIZE,
  TaskeratorDatabase
} from '../database';
import {
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted
} from 'rxdb/dist/types/types';

import { Auth0ContextInterface } from '@auth0/auth0-react';
import { LexoRank } from 'lexorank';
import { MaybePromise } from 'iter-tools/types/async-iterable';
import { Replicator } from './Replicator';
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';
import { TODO_FRAGMENT } from '../../graphql/fragments';
import { Todo } from '../schema/todos';
import { TodoGraphQL } from '../../graphql_artifacts/TodoGraphQL';

interface CheckpointType {
  id: string;
  updatedAt: string;
}

const DEFAULT_CHECKPOINT = {
  id: LOWEST_OBJECT_ID,
  updatedAt: new Date(0).toDateString()
};

export class TodoReplicator implements Replicator<CheckpointType> {
  private db: TaskeratorDatabase;

  constructor(db: TaskeratorDatabase) {
    this.db = db;
  }

  async setupGraphQLReplication(
    tokenFetcher: Auth0ContextInterface['getAccessTokenSilently']
  ): Promise<RxGraphQLReplicationState<Todo, CheckpointType>> {
    const token = await tokenFetcher();
    const replicationState = this.db.todos.syncGraphQL({
      url: { http: GRAPHQL_URL },
      headers: { authorization: `Bearer ${token}` },
      pull: {
        batchSize: REPLICATION_BATCH_SIZE,
        queryBuilder: this.pullQueryBuilder,
        modifier: (doc: TodoGraphQL): MaybePromise<WithDeleted<Todo>> => {
          return {
            profile_id: doc.profile_id,
            id: doc.id,
            completed_at: doc.completed_at || undefined,
            created_at: doc.created_at,
            deleted_at: doc.deleted_at || undefined,
            updated_at: doc.updated_at,
            title: doc.title,
            note: doc.note || '',
            rank: doc.rank,
            is_completed: doc.is_completed,
            _deleted: !!doc.deleted_at
          };
        },
        responseModifier: (
          plainResponse: ReplicationPullHandlerResult<Todo, CheckpointType>,
          origin,
          requestCheckpoint
        ): MaybePromise<ReplicationPullHandlerResult<Todo, CheckpointType>> => {
          const docs = (plainResponse as unknown as Todo[]).map((doc) => {
            return { ...doc, _deleted: !!doc.deleted_at };
          });
          return {
            documents: docs,
            checkpoint:
              docs.length === 0 || !requestCheckpoint
                ? DEFAULT_CHECKPOINT
                : {
                    id: docs[docs.length - 1].id,
                    updatedAt: docs[docs.length - 1].updated_at!
                  }
          };
        }
      },
      push: {
        batchSize: 1,
        queryBuilder: this.pushQueryBuilder
      },
      live: true
    });

    replicationState.error$.subscribe((error) => {
      if (!error.parameters) {
        console.error('Error that breaks type definitions (TodoReplicator)!');
        console.debug(error);

        return;
      }

      const firstError = error.parameters.errors?.[0] as unknown as Optional<HasuraError>;

      if (firstError?.extensions.code === 'invalid-jwt') {
        console.info('Refetching JWT due to expiration');

        tokenFetcher().then((token) =>
          replicationState.setHeaders({ authorization: `Bearer ${token}` })
        );

        return;
      }

      if (error.parameters.direction == 'push') {
        const documentData = error.parameters.pushRows![0].newDocumentState;

        if (
          firstError?.message ===
          'Uniqueness violation. duplicate key value violates unique constraint "todos_profile_id_rank_key"'
        ) {
          const newRank = LexoRank.parse(documentData.rank).genNext().toString();

          this.db.todos
            .findOne(documentData.id)
            .exec()
            .then((doc) => {
              doc?.update({
                $set: {
                  rank: newRank
                }
              });
            });

          documentData.rank = newRank;
        } else if (firstError?.extensions.code === 'permission-error') {
          console.error('Todo push replication failed due to a permissions issue', documentData);

          this.db.todos
            .findOne(documentData.id)
            .exec()
            .then((doc) => doc?.remove());

          documentData._deleted = true;
        } else {
          console.error('Todo push replication failed.', error);
        }
      } else if (error.parameters.direction == 'pull') {
        console.error('Todo pull replication failed.', error);
      } else {
        console.error('Unknown Todo error direction!', error);
      }
    });

    return replicationState;
  }

  pullQueryBuilder(latestPulledCheckpoint: Nullable<CheckpointType>, limit: number) {
    if (!latestPulledCheckpoint) {
      latestPulledCheckpoint = {
        id: LOWEST_OBJECT_ID,
        updatedAt: new Date(0).toDateString()
      };
    }

    const query = `
      ${TODO_FRAGMENT.loc?.source.body}
      query FetchTodos($updated_at: timestamptz!, $last_id: uuid, $limit: Int) {
        todos(limit: $limit, where: {
          _or: [
            { updated_at: { _gt: $updated_at } },
            { updated_at: { _eq: $updated_at }, id: { _gt: $last_id } }
          ]},
          order_by: [{ updated_at: asc }, { id: asc }]) {
            ...TodoGraphQL
        }
      }
    `;

    return {
      query,
      variables: {
        last_id: latestPulledCheckpoint.id,
        updated_at: latestPulledCheckpoint.updatedAt,
        limit: limit
      }
    };
  }

  pushQueryBuilder(docs: RxReplicationWriteToMasterRow<Todo>[]) {
    if (docs.length === 1) {
      const doc = docs[0].newDocumentState;

      if (doc._deleted) {
        const query = `
      mutation DeleteTodo($id: uuid!) {
        delete_todos_by_pk(id: $id) {
          id
        }
      }
      `;

        return {
          query: query,
          variables: {
            id: doc.id
          }
        };
      } else {
        const query = `
      ${TODO_FRAGMENT.loc?.source.body}
      mutation UpsertTodo($id: uuid!, $title: String!, $note: String, $rank: String!, $profile_id: uuid!, $is_completed: Boolean) {
        insert_todos_one(
          object: {id: $id, title: $title, note: $note, rank: $rank, profile_id: $profile_id, is_completed: $is_completed},
          on_conflict: {constraint: todos_pkey, update_columns: [title, note, rank, is_completed]}) {
            ...TodoGraphQL
        }
      }
    `;

        return {
          query: query,
          variables: {
            id: doc.id,
            profile_id: doc.profile_id,
            title: doc.title,
            note: (doc.note || '').length === 0 ? undefined : doc.note,
            rank: doc.rank,
            is_completed: doc.is_completed
          }
        };
      }
    } else {
      throw new Error('TodoReplicator pushes in batches > 1 are unsupported');
    }
  }

  /*
  subscription(
    client: Client,
    replicationState: RxGraphQLReplicationState<Todo, unknown>
  ): () => void {
    const query = `
    ${TODO_FRAGMENT.loc?.source.body}
    subscription onTodoChange {
      todos(limit: 1, order_by: {updated_at: desc}) {
        ...TodoGraphQL
      }
    }`;

    return client.subscribe(
      { query },
      {
        next: (data) => {
          console.log('WS todo data', data);
          replicationState.run();
        },
        error: (error) => console.error('WS todo error', error),
        complete: () => console.log('WS todo complete')
      }
    );
  }
  */
}
