import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from './Router';
import { RxdbWrapper } from './components/RxdbWrapper';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Auth0Provider
    audience='http://localhost:3010'
    domain='adaptivenoise.auth0.com'
    clientId='sKPY88rreeaB6OMrrP4yqAi0zuKrL0CT'
    cacheLocation='localstorage'
    redirectUri={window.location.origin}
    onRedirectCallback={(appState) =>
      (window.location.href =
        appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    }
  >
    <RxdbWrapper>
      <React.StrictMode>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </React.StrictMode>
    </RxdbWrapper>
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
