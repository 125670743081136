import { Button, List, Popconfirm } from 'antd';
import { Profile, ProfileDoc } from '../../db/schema/profiles';
import React, { PropsWithChildren } from 'react';

import { RxDocument } from 'rxdb';
import styles from './ProfileListItem.module.scss';

interface ProfileListItemProps {
  profile: Profile;
}

export function ProfileListItem(props: PropsWithChildren<ProfileListItemProps>) {
  const { profile } = props;

  return (
    <List.Item>
      <div key={profile.id} className={styles.listItem}>
        <div>{profile.name}</div>
        <Popconfirm
          title={'Are you sure you want to stop delete this profile and all its todos?'}
          cancelText='No'
          okText='Yes'
          onConfirm={(_) => (profile as RxDocument<ProfileDoc>).remove()}
        >
          <div>
            {' '}
            <Button danger>Delete</Button>{' '}
          </div>
        </Popconfirm>
      </div>
    </List.Item>
  );
}
