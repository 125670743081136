import { QueryConstructor, useRxData } from 'rxdb-hooks';
import React, { PropsWithChildren } from 'react';

import { Todo } from '../../db/schema/todos';
import { TodoChain } from '../../db/schema/todo_chains';
import { TodoChainEditor } from './TodoChainEditor';

interface EditTodoChainProps {
  todoChain: TodoChain;
  onCancel: () => void;
  onUpdate: (todoIDs: ID[]) => void;
}

export function EditTodoChain(props: PropsWithChildren<EditTodoChainProps>) {
  const { onCancel, onUpdate, todoChain } = props;

  // TODO (nirvdrum 09-Feb-2022): This currently fetches all todos for the profile, but we only need the ones ranked higher than or equal to the first todo in the chain.
  const todoQuery: QueryConstructor<Todo> = (collection) =>
    collection.find().where('profile_id').eq(todoChain.profile_id).sort({ rank: 'asc' });
  const { result: todos, isFetching } = useRxData('todos', todoQuery);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <TodoChainEditor
      availableTodos={todos}
      chainTodoIds={todoChain.todo_ids}
      onCancel={onCancel}
      onSubmit={onUpdate}
    />
  );
}
