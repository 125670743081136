import { Button, Result } from 'antd';
import { useNavigate, useRoutes } from 'react-router-dom';

import App from './components/App';
import React from 'react';

export function Router() {
  const navigate = useNavigate();

  const element = useRoutes([
    { path: '/', element: <App /> },
    { path: '/account', element: <App /> },
    { path: '/profiles/:profileId', element: <App /> },
    {
      path: '*',
      element: (
        <Result
          status='404'
          title='Page Not Found'
          extra={
            <Button type='primary' onClick={() => navigate('/')}>
              Back Home
            </Button>
          }
        />
      )
    }
  ]);

  return element;
}
