import { Button, Form, Input } from 'antd';
import React, { PropsWithChildren, useState } from 'react';

const TODO_TITLE_FIELD = 'todoTitle';
const TODO_NOTES_FIELD = 'todoNotes';

interface TodoFormProps {
  disabled: boolean;
  onSubmit: (title: string, note: string) => void;
}

export function TodoForm(props: PropsWithChildren<TodoFormProps>) {
  const { disabled, onSubmit } = props;
  const [form] = Form.useForm();
  const [showAddNote, setShowAddNote] = useState(false);

  const onFinish = (formData: Record<string, string>) => {
    onSubmit(formData[TODO_TITLE_FIELD], formData[TODO_NOTES_FIELD]);
    form.resetFields();
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 16 }
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 24, xs: { offset: 0, span: 24 } }
  };

  return (
    <Form {...layout} form={form} autoComplete='off' onFinish={onFinish}>
      <Form.Item
        name={TODO_TITLE_FIELD}
        label='New Todo:'
        rules={[{ required: true, message: 'Please add something to your todo' }]}
      >
        <Input autoFocus={true} onPressEnter={form.submit} />
      </Form.Item>
      {showAddNote ? (
        <Form.Item name={TODO_NOTES_FIELD} label='Notes:'>
          <Input />
        </Form.Item>
      ) : (
        <Form.Item>
          <Button type='link' style={{ paddingLeft: '0px' }} onClick={() => setShowAddNote(true)}>
            Add note
          </Button>
        </Form.Item>
      )}
      <Form.Item {...tailLayout}>
        <Button htmlType='submit' disabled={disabled}>
          +
        </Button>
      </Form.Item>
    </Form>
  );
}
