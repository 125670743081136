import { Empty, List } from 'antd';

import { Profile } from '../db/schema/profiles';
import { ProfileListHeader } from './Profiles/ProfileListHeader';
import { ProfileListItem } from './Profiles/ProfileListItem';
import React from 'react';
import { UserInfo } from './UserInfo';
import { maxRank } from '../lib/lexorankUtils';
import styles from './AccountTabPane.module.scss';

export interface Props {
  profiles: Profile[];
}

export function AccountTabPane(props: Props) {
  const { profiles } = props;

  const header = <ProfileListHeader maxProfileRank={maxRank(profiles)} />;

  return (
    <>
      <List bordered={true} className={styles.list} header={header} data-testid='account-tab'>
        {profiles.length > 0 ? (
          profiles.map((profile) => (
            <ProfileListItem key={profile.id} profile={profile}>
              {profile.name}
            </ProfileListItem>
          ))
        ) : (
          <Empty className={styles.listEmpty} />
        )}
      </List>
      <UserInfo />
    </>
  );
}
