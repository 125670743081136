import {
  GRAPHQL_URL,
  LOWEST_OBJECT_ID,
  REPLICATION_BATCH_SIZE,
  TaskeratorDatabase
} from '../database';
import {
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted
} from 'rxdb/dist/types/types';

import { Auth0ContextInterface } from '@auth0/auth0-react';
import { MaybePromise } from 'iter-tools/types/async-iterable';
import { Replicator } from './Replicator';
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';
import { TODO_CHAIN_FRAGMENT } from '../../graphql/fragments';
import { TodoChain } from '../schema/todo_chains';
import { TodoChainGraphQL } from '../../graphql_artifacts/TodoChainGraphQL';

interface CheckpointType {
  id: string;
  updatedAt: string;
}

const DEFAULT_CHECKPOINT = {
  id: LOWEST_OBJECT_ID,
  updatedAt: new Date(0).toDateString()
};

export class TodoChainReplicator implements Replicator<CheckpointType> {
  private db: TaskeratorDatabase;

  constructor(db: TaskeratorDatabase) {
    this.db = db;
  }

  async setupGraphQLReplication(
    tokenFetcher: Auth0ContextInterface['getAccessTokenSilently']
  ): Promise<RxGraphQLReplicationState<TodoChain, CheckpointType>> {
    const token = await tokenFetcher();
    const replicationState = this.db.todo_chains.syncGraphQL({
      url: { http: GRAPHQL_URL },
      headers: { authorization: `Bearer ${token}` },
      pull: {
        batchSize: REPLICATION_BATCH_SIZE,
        queryBuilder: this.pullQueryBuilder,
        modifier: (doc: TodoChainGraphQL): MaybePromise<WithDeleted<TodoChain>> => {
          return {
            profile_id: doc.profile_id,
            id: doc.id,
            todo_ids: doc.todo_ids,
            is_completed: doc.is_completed,
            completed_at: doc.completed_at || undefined,
            created_at: doc.created_at,
            deleted_at: doc.deleted_at || undefined,
            updated_at: doc.updated_at,
            _deleted: !!doc.deleted_at
          };
        },
        responseModifier: (
          plainResponse: ReplicationPullHandlerResult<TodoChain, CheckpointType>,
          origin,
          requestCheckpoint
        ): MaybePromise<ReplicationPullHandlerResult<TodoChain, CheckpointType>> => {
          const docs = (plainResponse as unknown as TodoChain[]).map((doc) => {
            return { ...doc, _deleted: !!doc.deleted_at };
          });
          return {
            documents: docs,
            checkpoint:
              docs.length === 0 || !requestCheckpoint
                ? DEFAULT_CHECKPOINT
                : {
                    id: docs[docs.length - 1].id,
                    updatedAt: docs[docs.length - 1].updated_at!
                  }
          };
        }
      },
      push: {
        batchSize: 1,
        queryBuilder: this.pushQueryBuilder
      },
      live: true
    });

    replicationState.error$.subscribe((error) => {
      if (!error.parameters) {
        console.error('Error that breaks type definitions (TodoChainReplicator)!');
        console.debug(error);

        return;
      }

      const firstError = error.parameters.errors?.[0] as unknown as Optional<HasuraError>;

      if (firstError?.extensions.code === 'invalid-jwt') {
        console.info('Refetching JWT due to expiration');

        tokenFetcher().then((token) =>
          replicationState.setHeaders({ authorization: `Bearer ${token}` })
        );

        return;
      }

      if (error.parameters.direction == 'push') {
        console.error('TodoChain push replication failed.', error);
      } else if (error.parameters.direction == 'pull') {
        console.error('TodoChain pull replication failed.', error);
      } else {
        console.error('Unknown TodoChain error direction!', error);
      }
    });

    return replicationState;
  }

  pullQueryBuilder(latestPulledCheckpoint: Nullable<CheckpointType>, limit: number) {
    if (!latestPulledCheckpoint) {
      latestPulledCheckpoint = {
        id: LOWEST_OBJECT_ID,
        updatedAt: new Date(0).toDateString()
      };
    }

    const query = `
      ${TODO_CHAIN_FRAGMENT.loc?.source.body}
      query FetchTodoChains($updated_at: timestamptz!, $last_id: uuid, $limit: Int) {
        todo_chains(limit: $limit, where: {
          _or: [
            { updated_at: { _gt: $updated_at } },
            { updated_at: { _eq: $updated_at }, id: { _gt: $last_id } }
          ]},
          order_by: [{ updated_at: asc }, { id: asc }]) {
            ...TodoChainGraphQL
        }
      }
    `;

    return {
      query,
      variables: {
        last_id: latestPulledCheckpoint.id,
        updated_at: latestPulledCheckpoint.updatedAt,
        limit: limit
      }
    };
  }

  pushQueryBuilder(docs: RxReplicationWriteToMasterRow<TodoChain>[]) {
    if (docs.length === 1) {
      const doc = docs[0].newDocumentState;

      if (doc._deleted) {
        const query = `
      mutation DeleteTodoChain($id: uuid!) {
        delete_todo_chains_by_pk(id: $id) {
          id
        }
      }
      `;

        return {
          query: query,
          variables: {
            id: doc.id
          }
        };
      } else {
        const query = `
      ${TODO_CHAIN_FRAGMENT.loc?.source.body}
      mutation UpsertTodoChain($id: uuid!, $profile_id: uuid!, $todo_ids: _uuid! $is_completed: Boolean, $updated_at: timestamptz!) {
        insert_todo_chains_one(
          object: {id: $id, profile_id: $profile_id, todo_ids: $todo_ids, is_completed: $is_completed},
          on_conflict: {constraint: todo_chains_pkey, update_columns: [todo_ids, is_completed], where: { updated_at: { _lte: $updated_at } } }) {
            ...TodoChainGraphQL
        }
      }
    `;

        return {
          query: query,
          variables: {
            id: doc.id,
            profile_id: doc.profile_id,
            todo_ids: `{${doc.todo_ids.join(',')}}`,
            is_completed: doc.is_completed,
            updated_at: doc.updated_at || new Date().toISOString()
          }
        };
      }
    } else {
      throw new Error('TodoChainReplicator pushes in batches > 1 are unsupported');
    }
  }

  /*
  subscription(
    client: Client,
    replicationState: RxGraphQLReplicationState<TodoChain, unknown>
  ): () => void {
    const query = `
    ${TODO_CHAIN_FRAGMENT.loc?.source.body}
    subscription onTodoChainChange {
      todo_chains(limit: 1, order_by: {updated_at: desc}) {
        ...TodoChainGraphQL
      }
    }`;

    return client.subscribe(
      { query },
      {
        next: (data) => {
          console.log('WS todo chain data', data);
          replicationState.run();
        },
        error: (error) => console.error('WS todo chain error', error),
        complete: () => console.log('WS todo chain complete')
      }
    );
  }*/
}
