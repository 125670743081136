import { Client, createClient } from 'graphql-ws';
import { ProfileCollection, profileMethods, profileSchema } from './schema/profiles';
import { RxDatabase, addRxPlugin, createRxDatabase } from 'rxdb';
import { TodoChainCollection, todoChainMethods, todoChainSchema } from './schema/todo_chains';
import { TodoCollection, todoMethods, todoSchema } from './schema/todos';

import { Auth0ContextInterface } from '@auth0/auth0-react';
import { DexieSettings } from 'rxdb/dist/types/types';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBReplicationHasuraGraphQLPlugin } from './replicators/hasura-replicator';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { getRxStorageDexie } from 'rxdb/plugins/dexie';

type TaskeratorDatabaseCollections = {
  profiles: ProfileCollection;
  todos: TodoCollection;
  todo_chains: TodoChainCollection;
};

const GRAPHQL_SERVER = process.env.REACT_APP_GRAPHQL_SERVER;
export const GRAPHQL_URL = GRAPHQL_SERVER + '/v1/graphql';
export const GRAPHQL_WS_URL = GRAPHQL_URL.replace('http', 'ws');

// Poll GraphQL server once every 10 minutes. Replication uses GraphQL subscriptions,
// so results should arrive quickly. But, in case the socket breaks,
// to ensure results eventually arrive.
export const REPLICATION_INTERVAL = 1000 * 60 * 10;

export const REPLICATION_BATCH_SIZE = 20;
export const LOWEST_OBJECT_ID = '00000000-0000-0000-0000-000000000000';

export type TaskeratorDatabase = RxDatabase<TaskeratorDatabaseCollections>;

export async function createDatabase(): Promise<TaskeratorDatabase> {
  let storageOptions: DexieSettings = {};

  if (process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fakeIndexedDB = require('fake-indexeddb');

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fakeIDBKeyRange = require('fake-indexeddb/lib/FDBKeyRange');

    storageOptions = {
      indexedDB: fakeIndexedDB,

      // eslint-disable-next-line @typescript-eslint/naming-convention
      IDBKeyRange: fakeIDBKeyRange
    };
  }

  addRxPlugin(RxDBQueryBuilderPlugin);
  addRxPlugin(RxDBDevModePlugin);
  addRxPlugin(RxDBReplicationHasuraGraphQLPlugin);
  addRxPlugin(RxDBUpdatePlugin);

  const db: TaskeratorDatabase = await createRxDatabase<TaskeratorDatabaseCollections>({
    name: 'taskerator', // <- name
    storage: getRxStorageDexie(storageOptions),
    ignoreDuplicate: process.env.NODE_ENV === 'test',
    multiInstance: process.env.NODE_ENV !== 'test', // <- multiInstance (optional, default: true)
    eventReduce: true // <- eventReduce (optional, default: true),
  });

  await db.addCollections({
    profiles: {
      schema: profileSchema,
      methods: profileMethods
    },
    todos: {
      schema: todoSchema,
      methods: todoMethods
    },
    todo_chains: {
      schema: todoChainSchema,
      methods: todoChainMethods
    }
  });

  return db;
}

export function createWebSocketClient(
  tokenFetcher: Auth0ContextInterface['getAccessTokenSilently']
): Client {
  return createClient({
    url: GRAPHQL_WS_URL,
    connectionParams: async () => {
      const token = await tokenFetcher();

      return {
        headers: { authorization: `Bearer ${token}` }
      };
    }
  });
}
