import { Button, Card, Modal } from 'antd';
import { QueryConstructor, useRxCollection, useRxData } from 'rxdb-hooks';
import React, { useState } from 'react';

import { ActiveTodo } from '../TodoChains/ActiveTodo';
import { NewTodoChain } from '../TodoChains/NewTodoChain';
import { TodoChain as TodoChainType } from '../../db/schema/todo_chains';
import { TodoDoc } from '../../db/schema/todos';
import styles from './TodoChain.module.scss';
import { v4 as uuidv4 } from 'uuid';

interface TodoChainProps {
  profileId: ID;
}

export function TodoChain(props: TodoChainProps) {
  const { profileId } = props;
  const [showCreateTodoChain, setShowCreateTodoChain] = useState(false);
  const todoChainsCollection = useRxCollection<TodoChainType>('todo_chains');

  const todoQuery: QueryConstructor<TodoDoc> = (collection) =>
    collection
      .find()
      .where('profile_id')
      .eq(profileId)
      .where('is_completed')
      .eq(false)
      .where('_deleted')
      .eq(false)
      .sort({ rank: 'asc' });

  const todoChainQuery: QueryConstructor<TodoChainType> = (collection) =>
    collection.find().where('profile_id').eq(profileId).where('is_completed').eq(false);

  const { result: todos, isFetching: isFetchingTodos } = useRxData('todos', todoQuery);
  const { result: todoChains, isFetching: isFetchingTodoChain } = useRxData(
    'todo_chains',
    todoChainQuery
  );
  const todoChain = todoChains[0];

  if (isFetchingTodos || isFetchingTodoChain) {
    return <div>Loading...</div>;
  }

  const closeModal = () => {
    setShowCreateTodoChain(false);
  };

  const createTodoChain = (todoIDs: ID[]) => {
    todoChainsCollection
      ?.insert({
        id: uuidv4(),
        profile_id: profileId,
        todo_ids: todoIDs,
        is_completed: false,
        created_at: new Date().toISOString()
      })
      .finally(closeModal);
  };

  if (showCreateTodoChain) {
    return (
      <Modal visible={true} width='90vw' footer={null} onCancel={closeModal}>
        <NewTodoChain
          todos={todos}
          onCancel={closeModal}
          onCreate={(todoIDs) => createTodoChain(todoIDs)}
        />
      </Modal>
    );
  }

  if (todoChain) {
    return <ActiveTodo todoChain={todoChain} />;
  } else {
    const message =
      todos.length > 0
        ? 'There is no active todo chain for this profile.'
        : 'You have no todos to complete. Congrats!';

    return (
      <Card title='Todo Chain'>
        <div>{message}</div>
        <div className={styles.buttonGroup}>
          <Button
            type='primary'
            disabled={todos.length === 0}
            onClick={() => setShowCreateTodoChain(true)}
          >
            Create a new todo chain
          </Button>
          <Button>Choose todo at random</Button>
        </div>
      </Card>
    );
  }
}
