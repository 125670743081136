import React, { PropsWithChildren, useEffect, useState } from 'react';
import { TaskeratorDatabase, createDatabase, createWebSocketClient } from '../db/database';

import { ProfileReplicator } from '../db/replicators/ProfileReplicator';
import { Provider } from 'rxdb-hooks';
import { TodoChainReplicator } from '../db/replicators/TodoChainReplicator';
import { TodoReplicator } from '../db/replicators/TodoReplicator';
import { useAuth0 } from '@auth0/auth0-react';

export function RxdbWrapper(props: PropsWithChildren<unknown>) {
  const [db, setDb] = useState<Nullable<TaskeratorDatabase>>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const databaseSetup = async () => {
      try {
        const db = await createDatabase();

        if (process.env.NODE_ENV !== 'test') {
          const wsClient = await createWebSocketClient(getAccessTokenSilently);

          const profileReplicator = new ProfileReplicator(db);
          const profileReplicationState = await profileReplicator.setupGraphQLReplication(
            getAccessTokenSilently
          );
          //profileReplicator.subscription(wsClient, profileReplicationState);

          const todoReplicator = new TodoReplicator(db);
          const todoReplicationState = await todoReplicator.setupGraphQLReplication(
            getAccessTokenSilently
          );
          //todoReplicator.subscription(wsClient, todoReplicationState);

          const todoChainReplicator = new TodoChainReplicator(db);
          const todoChainReplicationState = await todoChainReplicator.setupGraphQLReplication(
            getAccessTokenSilently
          );
          //todoChainReplicator.subscription(wsClient, todoChainReplicationState);

          console.log(profileReplicationState, todoReplicationState, todoChainReplicationState);
        }

        setDb(db);
      } catch (error) {
        console.error('Error setting up database', error);
      }
    };

    databaseSetup();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    return () => {
      db?.destroy();
    };
  }, [db]);

  return <Provider db={db || undefined}>{props.children}</Provider>;
}
