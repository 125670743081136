import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxCollection,
  RxDocument,
  RxJsonSchema,
  toTypedRxJsonSchema
} from 'rxdb';
import { MAX_TIMESTAMP_LENGTH, MAX_UUID_LENGTH } from './constraints';

import { Profile } from './profiles';
import { Todo } from './todos';

const todoChainSchemaLiteral = {
  title: 'TodoChain schema',
  description: 'Todo chain schema',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: MAX_UUID_LENGTH
    },
    profile_id: {
      type: 'string',
      maxLength: MAX_UUID_LENGTH
    },
    todo_ids: {
      type: 'array',
      items: { type: 'string', maxLength: MAX_UUID_LENGTH }
    },
    is_completed: {
      type: 'boolean'
    },
    completed_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    },
    created_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    },
    updated_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    },
    deleted_at: {
      type: 'string',
      format: 'date-time',
      maxLength: MAX_TIMESTAMP_LENGTH
    }
  },
  required: ['id', 'created_at', 'is_completed', 'profile_id', 'todo_ids'],
  indexes: ['completed_at', 'profile_id']
} as const;

const todoChainSchemaTyped = toTypedRxJsonSchema(todoChainSchemaLiteral);
export type TodoChain = ExtractDocumentTypeFromTypedRxJsonSchema<typeof todoChainSchemaTyped>;
export const todoChainSchema: RxJsonSchema<TodoChain> = todoChainSchemaLiteral;

type TodoChainMethods = {
  activeTodo: () => Promise<Nullable<Todo>>;
  markCompleted: () => Promise<TodoChain>;
  profile: () => Promise<Profile>;
  todos: () => Promise<Optional<Todo>[]>;
  updateTodoIds: (todoIds: string[]) => Promise<TodoChain>;
};

export const todoChainMethods: TodoChainMethods = {
  async activeTodo(this: TodoChainDoc): Promise<Nullable<Todo>> {
    const todos = await this.todos();

    for (let i = todos.length; i > 0; --i) {
      const todo = todos[i - 1];

      if (todo && !todo.completed_at) {
        return todo;
      }
    }

    return null;
  },

  async markCompleted(this: TodoChainDoc): Promise<TodoChain> {
    return this.update({
      $set: {
        completed_at: new Date().toISOString(),
        is_completed: true
      }
    });
  },

  async profile(this: TodoChainDoc): Promise<Profile> {
    return this.collection.database.profiles.findOne(this.profile_id).exec();
  },

  async todos(this: TodoChainDoc): Promise<Optional<Todo>[]> {
    const todoMap = await this.collection.database.todos.findByIds(this.todo_ids);

    return this.todo_ids.map((id: ID) => todoMap.get(id));
  },

  async updateTodoIds(this: TodoChainDoc, todoIds: string[]): Promise<TodoChain> {
    return this.update({
      $set: {
        todo_ids: todoIds
      }
    });
  }
};

type TodoChainCollectionMethods = unknown;

export type TodoChainDoc = RxDocument<TodoChain, TodoChainMethods>;
export type TodoChainCollection = RxCollection<
  TodoChain,
  TodoChainMethods,
  TodoChainCollectionMethods
>;
