import { LexoRank } from 'lexorank';

export function minRank(collection: ReadonlyArray<Rankable>): LexoRank {
  const length = collection.length;

  if (length > 0) {
    const minValue = collection.reduce((a, b) => (a.rank < b.rank ? a : b));

    return LexoRank.parse(minValue.rank);
  } else {
    return LexoRank.min();
  }
}

export function maxRank(collection: ReadonlyArray<Rankable>): LexoRank {
  const length = collection.length;

  if (length > 0) {
    const maxValue = collection.reduce((a, b) => (a.rank > b.rank ? a : b));

    return LexoRank.parse(maxValue.rank);
  } else {
    return LexoRank.min();
  }
}
