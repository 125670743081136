// Custom implementation of Array.prototype.findLast from:
// https://github.com/tc39/proposal-array-find-from-last
// TODO (nirvdrum 10-Feb-2022): Remove this after Firefox adds support for the method.
export function findLast<T>(array: T[], predicate: (item: T) => boolean): Optional<T> {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return array[i];
    }
  }

  return undefined;
}
