import { Button, Card, Modal } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Todo, TodoDoc } from '../../db/schema/todos';
import { TodoChain, TodoChainDoc } from '../../db/schema/todo_chains';
import { filter, size } from 'iter-tools';

import { EditTodoChain } from './EditTodoChain';
import { TodoListItem } from '../Todos/TodoListItem';
import { findLast } from '../../lib/arrayHelpers';
import styles from './ActiveTodo.module.scss';
import { useRxCollection } from 'rxdb-hooks';

interface ActiveTodoProps {
  todoChain: TodoChain;
}

const isActionable = (todo: Optional<Todo>): boolean => {
  return todo && !todo.is_completed && !todo.deleted_at ? true : false;
};

export function ActiveTodo(props: PropsWithChildren<ActiveTodoProps>) {
  const { todoChain } = props;
  const [activeTodo, setActiveTodo] = useState<Optional<TodoDoc>>(undefined);
  const [editTodoChain, setEditTodoChain] = useState(false);
  const todosCollection = useRxCollection<TodoDoc>('todos');

  useEffect(() => {
    const subscription = todosCollection?.findByIds$(todoChain.todo_ids).subscribe((todos) => {
      const unresolvedTodos = filter(isActionable, todos.values());

      if (size(unresolvedTodos) > 0) {
        const activeTodoId = findLast(todoChain.todo_ids, (id) => isActionable(todos.get(id)));
        const activeTodo = todos.get(activeTodoId!);

        setActiveTodo(activeTodo);
      } else {
        // Todos may be marked completed or deleted from outside of this component (e.g., via replication).
        // As such, we need to detect when the todo chain no longer has any actionable todos and mark it as completed.
        (todoChain as TodoChainDoc).markCompleted();
      }
    });

    return () => subscription?.unsubscribe();
  }, [todoChain, todoChain.todo_ids, todosCollection]);

  const closeModal = () => setEditTodoChain(false);

  const updateTodoChain = (ids: ID[]) => {
    (todoChain as TodoChainDoc).updateTodoIds(ids);
    closeModal();
  };

  if (editTodoChain) {
    return (
      <Modal visible={true} width='90vw' footer={null} onCancel={closeModal}>
        <EditTodoChain todoChain={todoChain} onCancel={closeModal} onUpdate={updateTodoChain} />
      </Modal>
    );
  }

  if (activeTodo) {
    // Marking the active todo as complete should update the todo list display and select the next active todo
    // Marking the active todo as "done for now" should complete the active todo and then clone it as a new todo added to the end of the list
    // Deleting the active todo should update the todo list display and select the next active todo -- should the chain's list of todo IDs be updated too?

    // After any of those actions, the todo chain should be editable again

    return (
      <Card title='Todo Chain'>
        <h3>Active todo:</h3>
        <TodoListItem todo={activeTodo} editable={true} />
        <div className={styles.buttonGroup}>
          <Button type='primary' onClick={() => activeTodo?.updateCompletion(true)}>
            Finished
          </Button>
          <Button onClick={() => activeTodo?.markPartiallyComplete()}>Done for Now...</Button>
          <Button danger onClick={() => (activeTodo as TodoDoc).remove()}>
            Delete
          </Button>
          <Button onClick={() => setEditTodoChain(true)}>Edit todo chain</Button>
        </div>
      </Card>
    );
  } else {
    return (
      <Card title='Todo Chain'>
        Loading...<span>{todoChain.is_completed ? 'completed' : 'not completed'}</span>
      </Card>
    );
  }
}
