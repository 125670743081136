import React, { PropsWithChildren } from 'react';

import { Todo } from '../../db/schema/todos';
import { TodoChainEditor } from './TodoChainEditor';
import { minRank } from '../../lib/lexorankUtils';

interface NewTodoChainProps {
  todos: Todo[];
  onCancel: () => void;
  onCreate: (todoIDs: ID[]) => void;
}

export function NewTodoChain(props: PropsWithChildren<NewTodoChainProps>) {
  const { onCancel, onCreate, todos } = props;

  if (todos.length === 0) {
    return <div>There are no available todos to add to the chain.</div>;
  }

  const lowestRankAvailableTodo =
    todos.find((t) => t.rank === minRank(todos).toString())?.id || todos[0].id;

  return (
    <TodoChainEditor
      availableTodos={todos}
      chainTodoIds={[lowestRankAvailableTodo]}
      onCancel={onCancel}
      onSubmit={onCreate}
    />
  );
}
